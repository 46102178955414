import {
  Stack,
  Button,
  Box,
  ClickAwayListener,
  Divider,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Typography,
  Tooltip,
  Modal,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useParams } from "react-router";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  handleDjangoDataRequest,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../utils/apiUtils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import CommonSlider from "./CommonSlider";
import moment from "moment-timezone";
import CloseIcon from "@mui/icons-material/Close";

const AddonsPricing = ({ basicInfo, RowsData }) => {
  // getting the hotel currency

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotel_id");
  const groupId = url.searchParams.get("groupId");
  const [selectedFeature, setSelectedFeature] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setdeleteModal(true);
  const handleCloseDeleteModal = () => {
    setdeleteModal(false);
    setselectedRowData("");
  };

  const [editModal, seteditModal] = useState(false);
  const handleOpenEditModal = () => seteditModal(true);
  const handleCloseEditModal = () => seteditModal(false);

  const [addModal, setAddModal] = useState(false);
  const handleOpenAddModal = () => {
    setselectedRowData({});
    setAddModal(true);
  };
  const handleCloseAddModal = () => setAddModal(false);
  // Get All report data
  const [reportsData, setreportsData] = useState([]);
  const [selectedRowData, setselectedRowData] = useState({});
  const [loading, setloading] = useState(false);
  const [featureData, setfeatureData] = useState({});
  const [hotelData, sethotelData] = useState([]);
  const [selectedHotel, setselectedHotel] = useState("");

  console.log(groupId,"groupid")
  console.log(RowsData,"RowsData")
  useEffect(() => {
    const data = RowsData?.filter(
      (item) =>
        Number(item.group_id) === Number(groupId) 
        // &&
        // item.property_status === "ACTIVE"
    );
    sethotelData(data);
    if (data.length > 0) {
      console.log(data,"data");
      setselectedHotel(data[0].hotel_id);
    }
  }, [groupId, RowsData]);

  useEffect(() => {
    getAllData();
    getAllFeatureSet();
  }, []);

  const getAllData = () => {
    handleSpringDataRequest(
      `core/api/v1/hotelPricingAddons/get?groupId=${groupId}`
    ).then((res) => setreportsData(res));
  };

  const removeDuplicates = (obj) => {
    const uniqueValues = new Set();
    const result = {};

    for (const [key, value] of Object.entries(obj)) {
      if (!uniqueValues.has(value)) {
        uniqueValues.add(value);
        result[key] = value;
      }
    }

    return result;
  };
  const getAllFeatureSet = () => {
    handleSpringDataRequest(
      `core/api/v1/accesscontrol/getFeatureSetForHotel?hotelId=${hotelId}`
    )
      .then((res) => {
        setfeatureData(removeDuplicates(res));
      })
      .catch((err) => toast.error("Error in fecting hotel access"));
  };

  const columns = [
    {
      dataField: "hotel_id",
      text: "Hotel Id",
      headerStyle: {
        fontSize: "12px",
        width: "125px",
      },
    },
    {
      dataField: "description",
      text: "Descritpion",
      headerStyle: {
        fontSize: "12px",
        width: "150px",
        flexWrap: "wrap",
        wordBreak: "break-all",
      },
    },
    {
      dataField: "type",
      text: "Type",
      headerStyle: {
        fontSize: "12px",
        width: "150px",
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      headerStyle: {
        fontSize: "12px",
        width: "180px",
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: {
        fontSize: "12px",
        width: "180px",
      },
    },
    {
      isDummyField: true,
      text: `${"Actions"}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "115px",
      },
      formatter: (cell, row) => (
        <Stack direction="row" spacing={2}>
          <Tooltip title={`${"Edit item"}`}>
            <EditIcon
              onClick={() => {
                setselectedRowData(row);
                handleOpenEditModal();
              }}
            />
          </Tooltip>
          <Tooltip title={`${"Delete item"}`}>
            <DeleteIcon
              onClick={() => {
                setselectedRowData(row);
                handleOpenDeleteModal();
              }}
            />
          </Tooltip>
        </Stack>
      ),
      editable: false,
    },
  ];

  const deleteAddon = () => {
    setloading(true);
    handleSpringDataMutationRequest(
      "DELETE",
      `core/api/v1/hotelPricingAddons/delete-hotel-pricing-addon?hotelId=${selectedRowData.hotel_id}&hotelPricingAddonId=${selectedRowData.id}`
    )
      .then(() => {
        toast.success(" Deleted Successfully");
        handleCloseDeleteModal();
        getAllData();
        setloading(false);
      })
      .catch(() => {
        toast.error("Something went wrong");
        setloading(false);
      });
  };
  const [decription, setdecription] = useState("");
  const [amount, setamount] = useState(0);
  const [status, setstatus] = useState("ACTIVE");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const createAddon = () => {
    if (
      selectedFeature === "" ||
      selectedFeature === undefined ||
      selectedFeature === null
    ) {
      return toast.error("Please select feature");
    }

    if (amount === "" || amount === undefined || amount === null) {
      return toast.error("Please enter amount");
    }
    if (
      selectedHotel === "" ||
      selectedHotel === undefined ||
      selectedHotel === null
    ) {
      return toast.error("Please enter hotel");
    }
    if (decription === "" || decription === undefined || decription === null) {
      return toast.error("Please enter description");
    }

    setloading(true);
    const payload = {
      id: null,
      hotelId: hotelId,
      groupId: groupId,
      description: decription,
      type: "ADDON",
      amount: amount,
      status: status,
      addon_feature_id: selectedFeature,
      createdAt: null,
      modifiedAt: null,
      startDate: startDate,
      endDate: endDate,
    };
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/hotelPricingAddons/create`,
      payload
    )
      .then((res) => {
        toast.success("Successfully created addon");
        setloading(false);
        getAllData();
        handleCloseAddModal();
      })
      .catch((err) => {
        toast.error("Error in adding subscription");
        setloading(false);
      });
  };

  useEffect(() => {
    if (
      selectedRowData != null &&
      selectedRowData != undefined &&
      Object.entries(selectedRowData).length > 0
    ) {
      setdecription(selectedRowData.description);
      setamount(selectedRowData.amount);
      setstatus(selectedRowData.status);
      setSelectedFeature(selectedRowData.addon_feature_id);
      setstartDate(selectedRowData.start_date);
      setendDate(selectedRowData.end_date);
    } else {
      setdecription("");
      setamount(0);
      setstatus("ACTIVE");
      setSelectedFeature("");
      setstartDate(moment().format("YYYY-MM-DD"));
      setendDate(moment().add(1, "year").format("YYYY-MM-DD"));
    }
  }, [selectedRowData, featureData]);

  const editAddon = () => {
    if (
      selectedFeature === "" ||
      selectedFeature === undefined ||
      selectedFeature === null
    ) {
      return toast.error("Please select feature");
    }

    if (amount === "" || amount === undefined || amount === null) {
      return toast.error("Please enter amount");
    }
    if (decription === "" || decription === undefined || decription === null) {
      return toast.error("Please enter description");
    }

    setloading(true);
    const payload = {
      id: selectedRowData.id,
      description: decription,
      type: "ADDON",
      amount: amount,
      status: status,
      addon_feature_id: selectedFeature,
      hotelId: selectedRowData.hotel_id,
      groupId: selectedRowData.group_id,
      createdAt: selectedRowData.created_at,
      startDate: startDate,
      endDate: endDate,
      modifiedAt: moment().unix(),
    };

    handleSpringDataMutationRequest(
      "PUT",
      `core/api/v1/hotelPricingAddons/update`,
      payload
    )
      .then((res) => {
        toast.success("Successfully updated addon");
        setloading(false);
        getAllData();
        handleCloseEditModal();
      })
      .catch((err) => {
        toast.error("Error in updating subscription");
        setloading(false);
      });
  };

  return (
    <>
      <h1 className="mt-3 mb-3">{"Hotel pricing addon"}</h1>
      <Divider className="mt-2 mb-2" />

      <Button
        variant="custom-button"
        onClick={handleOpenAddModal}
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        Add pricing addon
      </Button>

      {reportsData ? (
        <div className="App">
          {/* {reportsColumns.length > 0 && ( */}
          <BootstrapTable keyField="id" data={reportsData} columns={columns} />
          {/* )} */}
        </div>
      ) : (
        <> </>
      )}
      <Modal
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">
                Delete Hotel Pricing Addon
              </div>
              <div
                className="close"
                onClick={() => {
                  setloading(false);
                  handleCloseDeleteModal();
                }}
              >
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <h4>{`Are you sure you want to remove hotel pricing addon ${selectedRowData.description} of amount ${selectedRowData.amount} for hotel - ${selectedRowData.hotel_id} ?`}</h4>
              </div>
            </div>
            <div className="w-100">
              <Button
                variant="custom-button"
                style={{
                  height: "2rem",
                  marginTop: "2rem",
                  width: "8rem",
                  float: "right",
                }}
                onClick={() => {
                  deleteAddon();
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${"Save"}`
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <CommonSlider
        open={editModal}
        onClose={() => {
          handleCloseEditModal();
        }}
        onBackdropClick={() => {
          handleCloseEditModal();
        }}
      >
        <div style={{ padding: "2rem" }}>
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <h3>Edit Hotel Pricing Addon</h3>
            <CloseIcon onClick={() => handleCloseEditModal()} />
          </div>

          <Divider style={{ marginBottom: "2rem" }} />
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label="Description"
            variant="outlined"
            value={decription}
            onChange={(e) => {
              setdecription(e.target.value);
            }}
            required
          />
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label="Amount"
            variant="outlined"
            value={amount}
            style={{ marginTop: "2rem", marginBottom: "2rem" }}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
                setamount(inputValue);
              }
            }}
            inputProps={{
              inputMode: "decimal", // This brings up the numeric keypad on mobile devices
            }}
            required
          />

          <FormControl fullWidth size="small">
            <InputLabel id="feature-select-label">Feature</InputLabel>
            <Select
              labelId="feature-select-label"
              id="feature-select"
              value={selectedFeature}
              label="Feature"
              onChange={(e) => setSelectedFeature(e.target.value)}
            >
              {Object.entries(featureData).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            size="small"
            style={{ marginTop: "2rem", marginBottom: "2rem" }}
          >
            <InputLabel id="feature-select-label">Status</InputLabel>
            <Select
              labelId="feature-select-label"
              id="feature-select"
              value={status}
              label="Feature"
              onChange={(e) => setstatus(e.target.value)}
            >
              <MenuItem key={"ACTIVE"} value={"ACTIVE"}>
                {"ACTIVE"}
              </MenuItem>
              <MenuItem key={"INACTIVE"} value={"INACTIVE"}>
                {"INACTIVE"}
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Start Date"
            type="date"
            size="small"
            fullWidth
            value={startDate}
            onChange={(e) => setstartDate(e.target.value)}
          />

          <TextField
            label="End Date"
            type="date"
            size="small"
            fullWidth
            value={endDate}
            style={{ marginTop: "2rem" }}
            onChange={(e) => setendDate(e.target.value)}
          />

          <div className="w-100">
            <Button
              variant="custom-button"
              style={{ float: "right", marginTop: "2rem" }}
              onClick={editAddon}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                `${"Save"}`
              )}
            </Button>
          </div>
        </div>
      </CommonSlider>

      <CommonSlider
        open={addModal}
        onClose={() => {
          handleCloseAddModal();
        }}
        onBackdropClick={() => {
          handleCloseAddModal();
        }}
      >
        <div style={{ padding: "2rem" }}>
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <h3>Add Hotel Pricing Addon</h3>
            <CloseIcon onClick={() => handleCloseAddModal()} />
          </div>

          <Divider style={{ marginBottom: "2rem" }} />
          <FormControl fullWidth size="small" style={{ marginTop: "2rem" }}>
            <InputLabel id="feature-select-label">Hotel</InputLabel>
            <Select
              labelId="feature-select-label"
              id="feature-select"
              value={selectedHotel}
              label="Hotel"
              style={{ marginBottom: "2rem" }}
              onChange={(e) => setselectedHotel(e.target.value)}
            >
              {hotelData
              ?.filter(item => item.hotel_id === selectedHotel)
              .map((item) => (
                <MenuItem key={item.hotel_id} value={item.hotel_id}>
                  {`${item.name} - ${item.hotel_id}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label="Description"
            variant="outlined"
            value={decription}
            onChange={(e) => {
              setdecription(e.target.value);
            }}
            required
          />
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label="Amount"
            variant="outlined"
            value={amount}
            style={{ marginTop: "2rem", marginBottom: "2rem" }}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
                setamount(inputValue);
              }
            }}
            inputProps={{
              inputMode: "decimal", // This brings up the numeric keypad on mobile devices
            }}
            required
          />

          <FormControl fullWidth size="small">
            <InputLabel id="feature-select-label">Feature</InputLabel>
            <Select
              labelId="feature-select-label"
              id="feature-select"
              value={selectedFeature}
              label="Feature"
              onChange={(e) => setSelectedFeature(e.target.value)}
            >
              {Object.entries(featureData).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" style={{ marginTop: "2rem" }}>
            <InputLabel id="feature-select-label">Status</InputLabel>
            <Select
              labelId="feature-select-label"
              id="feature-select"
              value={status}
              label="Feature"
              onChange={(e) => setstatus(e.target.value)}
            >
              <MenuItem key={"ACTIVE"} value={"ACTIVE"}>
                {"ACTIVE"}
              </MenuItem>
              <MenuItem key={"INACTIVE"} value={"INACTIVE"}>
                {"INACTIVE"}
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Start Date"
            type="date"
            size="small"
            fullWidth
            style={{ marginTop: "2rem" }}
            value={startDate}
            onChange={(e) => setstartDate(e.target.value)}
          />

        {status === "INACTIVE" && (
          <TextField
            label="End Date"
            type="date"
            size="small"
            fullWidth
            value={endDate}
            style={{ marginTop: "2rem" }}
            onChange={(e) => setendDate(e.target.value)}
          />
        )}

          <div className="w-100">
            <Button
              variant="custom-button"
              style={{ float: "right", marginTop: "2rem" }}
              onClick={createAddon}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                `${"Save"}`
              )}
            </Button>
          </div>
        </div>
      </CommonSlider>
    </>
  );
};

export default AddonsPricing;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
